import Swal from "sweetalert2";
import type { SweetAlertOptions, SweetAlertIcon } from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const AOpts = (icon: SweetAlertIcon | undefined): any => {
    return {
        icon: icon,
        toast: true,
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: false,
        position: "top",
        // animation: false,
    };
};

const Success = (opt: SweetAlertOptions) => Swal.fire({ ...AOpts("success"), ...opt });
const Warning = (opt: SweetAlertOptions) => Swal.fire({ ...AOpts("warning"), ...opt });
const Error = (opt: SweetAlertOptions) => Swal.fire({ ...AOpts("error"), ...opt });
const Info = (opt: SweetAlertOptions) => Swal.fire({ ...AOpts("info"), ...opt });

const Confirm = (opt: SweetAlertOptions) => {
    const options: SweetAlertOptions = {
        title: "",
        html: "<span style='font-size:15px; color:#686868;'>Are you sure you want to delete this page? <br> Once deleted, you will not be able to recover this.</span>",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: `<span style='font-size:14px'>Delete</span>`,
        cancelButtonText: `<span style='font-size:14px'>Cancel</span>`,
        confirmButtonColor: "#d3494e",
        customClass: {
            confirmButton: "btn-sm ml-3 custom-confirm-btn",
            cancelButton: "btn btn-secondary btn-sm custom-cancel-btn",
        },
        width: "470px",
        allowOutsideClick: false,
        allowEscapeKey: false,
        animation: false,
        ...opt,
    };

    return Swal.fire(options);
};
const ConfirmWithYesAndNo = (opt: SweetAlertOptions) => {
    const options: SweetAlertOptions = {
        title: "",
        html: "<span style='font-size:15px; color:#686868;'>Are you sure you want to make these items ready to migrate?</span>",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
        customClass: {
            confirmButton: "btn btn-primary btn-sm ml-3",
            cancelButton: "btn btn-secondary btn-sm",
        },
        width: "470px",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        animation: false,
        ...opt,
    };

    return Swal.fire(options);
};

const WarningWithOk = (opt: SweetAlertOptions) => {
	const options: SweetAlertOptions = {
		html: `<span style='font-size:15px; color:#686868;'> You have no longer have access to this form. Please contact the administrator.</span>`,
		reverseButtons: true,
		confirmButtonText: `OK`,
		allowOutsideClick: () => false,
		width: "470px",
		showClass: { backdrop: "swal2-noanimation", popup: "", icon: "" },
		hideClass: { popup: "" },
		showLoaderOnConfirm: true,
		...opt,
	};
	return Swal.fire(options);
}

export { Success, Warning, Error, Info, Confirm, ConfirmWithYesAndNo, WarningWithOk };
