import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    useTable,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useRowSelect,
} from "react-table";
import Select from "react-select";
import loaderImage from '../../../assets/loader-sm.gif';

const DataGrid = ({
    columns,
    data,
    pageIndex,
    pageSize,
    totalRecord,
    onPageChange,
    onPageSizeChange,
    onSelectionChange,
    onSearchListChange,
    searchFormRecords,
    gridRefresh,
    highlight,
    setHighlight,
    viewDetails,
    loader,
    gridOptions
}: any) => {
    const [searchList, setSearchList]: any = useState({});
    const [searchJson, setSearchJson]: any = useState({});
    const [selectedIds, setSelectedIds]:any = useState([]);
    const gridContainerRef:any = useRef(null);
    const [dynamicOptionsMap, setDynamicOptionsMap] = useState<{ [key: string]: any[] }>({});
    

    const actionColumn = {
        Header: () => <span className="grid-col-freeze">Action</span>,
        accessor: 'action',
        id: 'action',
        Cell: ({ row }: any) => (
            <div className="eyeicon tooltip-container" onClick={() => viewDetails(row.original)}>
                <i className="fa fa-eye"></i>
            </div>
        ),
        disableFilters: true,
        disableSortBy: true,
        width: 60,
    };
    const modifiedColumns = useMemo(() => gridOptions.actionColumn !==false ?  [...columns, actionColumn] : columns, [
        columns,
    ]);

    // Fetch options when columns change
    useEffect(() => {
        const fetchOptionsForColumn = async (column: any) => {
            if (column && column.choicesByUrl && column.choicesByUrl?.url) {
                try {
                    const response = await fetch(column.choicesByUrl.url);
                    const data = await response.json();
                    const fetchedOptions = data.data.map((item: any) => ({
                        value: item.Value,
                        label: item.Value,
                    }));
                    setDynamicOptionsMap((prev) => ({
                        ...prev,
                        [column.accessor]: fetchedOptions,
                    }));
                } catch (error) {
                    console.error('Error fetching options for column:', column.id, error);
                }
            } else {
                const staticOptions = column.choices?.map((choice: any) => {
                    const choiceValue = typeof choice.Key === 'object' ? choice.Key.value : choice.Key;
                    const choiceKey = typeof choice.Value === 'object' ? choice.Value.text : choice.Value;
                
                    return {
                        value: choiceValue,  
                        label: choiceValue, 
                    };
                }) || [];
                setDynamicOptionsMap((prev) => ({
                    ...prev,
                    [column.accessor]: staticOptions,
                }));
            }
        };
        columns.forEach((column: any) => {
            if (column.type === "dropdown" || column.type === "tagbox") {
                fetchOptionsForColumn(column);
            }
        });
    }, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        selectedFlatRows,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex: tablePageIndex, pageSize: tablePageSize },
        setFilter,
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            initialState: { pageIndex, pageSize },
            manualPagination: true,
            pageCount: Math.ceil(totalRecord / pageSize),
        },
        useFilters,
        useSortBy,
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowSelect,
        (hooks: any) => {
            hooks.visibleColumns.push((columns: any) => [
                ...(gridOptions.rowSelection !== false
                    ? [
                        {
                            id: "selection",
                            Header: ({ getToggleAllPageRowsSelectedProps }: any) => {
                                const allPageRowsSelectedProps = getToggleAllPageRowsSelectedProps();
                                return (
                                    <div>
                                        <input
                                            type="checkbox"
                                            {...allPageRowsSelectedProps}
                                            indeterminate={
                                                allPageRowsSelectedProps.indeterminate ? true : undefined
                                            }
                                        />
                                    </div>
                                );
                            },
                            Cell: ({ row }: any) => {
                                const rowSelectedProps = row.getToggleRowSelectedProps();
                                return (
                                    <div>
                                        <input
                                            type="checkbox"
                                            {...rowSelectedProps}
                                            indeterminate={
                                                rowSelectedProps.indeterminate ? true : undefined
                                            }
                                        />
                                    </div>
                                );
                            },
                            disableSortBy: true,
                            disableFilters: true,
                            width: 50,
                        },
                      ]
                    : []),
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        onSelectionChange(selectedFlatRows.map((row: any) => row.original));
    }, [selectedFlatRows]);

    useEffect(() => {
        setSearchJson({});
        setSearchList((prevList: any) => {
            const updatedList = { ...prevList };
            selectedIds.forEach((id: any) => {
                if(id === "CreatedAt"){
                    updatedList[id].from = "";
                    updatedList[id].to = "";
                }
                updatedList[id] = "";
            });
            return updatedList;
        });
        setSelectedIds([]);
        setHighlight(false);
        const scrollContainer = gridContainerRef.current;
        if (scrollContainer) {
            // Use requestAnimationFrame to ensure that the DOM element is fully rendered before accessing it
            requestAnimationFrame(() => {
                scrollContainer.scrollLeft = 0;
            });
        }
    }, [gridRefresh])

    const onDateChange = (field: any, key: string, event: any) => {
        
        if (field && event.target.value) {
            setSearchList((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
            setSearchJson((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
            setSelectedIds((prevIds: any[]) => {
                if (!prevIds.includes(field)) {
                    return [...prevIds, field];
                }
                return prevIds;
            });
        }
    };

    const onChangeFilterForSelect = (field: any, value: any) => {
        if (field) {
            if (Array.isArray(value) && value.length === 0) {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSearchList((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSelectedIds((prevIds: any[]) => prevIds.filter(id => id !== field));
            } else if (value === null || typeof value === "undefined") {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSearchList((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSelectedIds((prevIds: any[]) => prevIds.filter(id => id !== field));
            } else if (Array.isArray(value)) {
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSearchList((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSelectedIds((prevIds: any[]) => {
                    if (!prevIds.includes(field)) {
                        return [...prevIds, field];
                    }
                    return prevIds;
                });
            } else if (typeof value === "object") {
                let obj: any = {};
                obj["Key"] = value.label;
                obj["Value"] = value.value;
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: obj
                }));
                setSearchList((prevList: any) => ({
                    ...prevList,
                    [field]: obj
                }));
                setSelectedIds((prevIds: any[]) => {
                    if (!prevIds.includes(field)) {
                        return [...prevIds, field];
                    }
                    return prevIds;
                });
            } else {
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSearchList((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSelectedIds((prevIds: any[]) => {
                    if (!prevIds.includes(field)) {
                        return [...prevIds, field];
                    }
                    return prevIds;
                });
            }
        }
    }

    const onChangeFilter = (field: any, value: any) => {
        setSearchJson((prevList: any) => ({
            ...prevList,
            [field]: value
        }));
        setSearchList((prevList: any) => ({
            ...prevList,
            [field]: value
        }));
        setSelectedIds((prevIds: any[]) => {
            if (!prevIds.includes(field)) {
                return [...prevIds, field];
            }
            return prevIds;
        });
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            searchFormRecords();
            setHighlight(true);
        }
    }

    useEffect(() => {
        onSearchListChange(searchJson);
    }, [searchJson]);

    const renderFilter = (column: any) => {
        const { id, type, choices, inputType } = column;
        if (id === "tp_serialNumber") return null;
        const options = dynamicOptionsMap[id] || [];
        if (inputType === "date" || inputType === "datetime-local") {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="date"
                        className="inputtext"
                        value={searchList[id]?.from || ""}
                        onChange={(event) => onDateChange(id, "from", event)}
                    />
                    <span style={{ margin: '0 5px' }}>-</span>
                    <input
                        type="date"
                        className="inputtext"
                        value={searchList[id]?.to || ""}
                        onChange={(event) => onDateChange(id, "to", event)}
                    />
                </div>
            )
        }

        switch (type) {
            case "checkbox":
                return (
                    <Select
                    isMulti
                    value={Array.isArray(searchList[id]) ? searchList[id].map((item: string) => ({ value: item, label: item })) : []}
                    onChange={(value: any) => onChangeFilterForSelect(id, value.map((v: any) => v.value))}
                    options={choices.map((choice: any) => ({ value: choice.Key, label: choice.Value }))}
                />
                );
                case "tagbox":
                return (
                    <Select
                        isMulti
                        value={
                            Array.isArray(searchList[id]) && searchList[id].length
                                ? searchList[id].map((item: string) => ({ value: item, label: item }))
                                : []
                        }
                        onChange={(value: any) => onChangeFilterForSelect(id, value.map((v: any) => v.value))}
                        options={options}
                    />
                );
                case "dropdown":
                    return (
                        <Select
                            isClearable
                            value={
                                searchList[id] && searchList[id].Key && searchList[id].Value
                                    ? { value: searchList[id].Key, label: searchList[id].Value }
                                    : null
                            }
                            onChange={(selectedOption: any) => onChangeFilterForSelect(id, selectedOption)}
                            options={options}
                        />
                    );
            case "CreatedAt":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.from || ""}
                            onChange={(event) => onDateChange(id, "from", event)}
                        />
                        <span style={{ margin: '0 5px' }}>-</span>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.to || ""}
                            onChange={(event) => onDateChange(id, "to", event)}
                        />
                    </div>
                );
            case "datepicker":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.from || ""}
                            onChange={(event) => onDateChange(id, "from", event)}
                        />
                        <span style={{ margin: '0 5px' }}>-</span>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.to || ""}
                            onChange={(event) => onDateChange(id, "to", event)}
                        />
                    </div>
                );
            case "privacypolicy":
            case "termsconditions":
            case "boolean":
                const selectedOption = searchList[id] === true ? { value: true, label: 'Yes' } : searchList[id] === false ? { value: false, label: 'No' } : null;
                return (
                    <Select
                        isClearable
                        value={selectedOption}
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            case "file":
                const selectedOption1 = searchList[id] === true ? { value: true, label: 'Yes' } : searchList[id] === false ? { value: false, label: 'No' } : null;
                return (
                    <Select
                        isClearable
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        value={selectedOption1}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            default:
                return (
                    <input className="inputtext"
                        onChange={(e) => onChangeFilter(id, e.target.value || undefined)}
                        value={searchList[id] || ''}
                        onKeyDown={handleKeyDown}
                    />
                );
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = 5;

        if (pageCount <= totalPageNumbers) {
            for (let i = 0; i < pageCount; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }
        } else {
            let startPage = Math.max(0, tablePageIndex - Math.floor(totalPageNumbers / 2));
            let endPage = startPage + totalPageNumbers - 1;

            if (endPage >= pageCount) {
                endPage = pageCount - 1;
                startPage = endPage - totalPageNumbers + 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }

            if (startPage > 0) {
                pageNumbers.unshift(<span key="ellipsis1">...</span>);
                pageNumbers.unshift(
                    <button
                        key={0}
                        onClick={() => {
                            gotoPage(0);
                            onPageChange(0);
                        }}
                        className={tablePageIndex === 0 ? "active" : ""}
                    >
                        1
                    </button>
                );
            }

            if (endPage < pageCount - 1) {
                pageNumbers.push(<span key="ellipsis2">...</span>);
                pageNumbers.push(
                    <button
                        key={pageCount - 1}
                        onClick={() => {
                            gotoPage(pageCount - 1);
                            onPageChange(pageCount - 1);
                        }}
                        className={tablePageIndex === pageCount - 1 ? "active" : ""}
                    >
                        {pageCount}
                    </button>
                );
            }
        }

        return pageNumbers;
    };

    const highlightText = (cell: any) => {
        let searchStr = searchJson[cell.column.id];
        if (cell.column.type === "datepicker" || cell.column.inputType === "date" || cell.column.inputType === "datetime-local" || cell.column.id === "CreatedAt") return cell.render("Cell");
        if (cell.column.type === "file" && searchStr) {
            const value = cell.value ? "Yes" : "No";
            searchStr = searchStr ? "Yes" : "No";
            const regex = new RegExp(`(${searchStr})`, 'gi');
            const parts = value.toString().split(regex);
            const data = parts.map((part: any, index: any) => part === searchStr ?
                (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                : (part));
            return data
        }
        if (searchStr) {
            if (Array.isArray(cell.value) && cell.column.type != "file") {
                return cell.value.map((item: any, idx: number) => {
                    // Create a regex that matches any of the strings in searchStr
                    if(Array.isArray(searchStr))  var  regex = new RegExp(`(${searchStr.join("|")})`, "gi"); // Highlight all search strings
                   else  var regex = new RegExp(`(${searchStr})`, "gi"); //

                    // Split each item into parts based on the regex match
                    const parts = item.toString().split(regex);

                    return (
                        <span key={idx}>
                            {parts.map((part: string, index: number) => (
                                <>
                                    {part.match(regex) ? (
                                        <span key={index} style={{ backgroundColor: "#fc0" }}>
                                            {part}
                                        </span>
                                    ) : (
                                        part
                                    )}
                                    {/* Add a comma after each part except the last one */}
                                    {index < parts.length - 1 && <span key={`comma-${index}`}>, </span>}
                                </>
                            ))}
                        </span>
                    );
                });
            } else if (typeof cell.value === "object") {
                if (cell.column.type === "fullname") {
                    const { prefixDropdown, prefixText, firstName, middleName, lastName } = cell.value;
                    const fullname = (prefixDropdown || "") + " " + (prefixText || "") + " " + (firstName || "") + " " + (middleName || "") + " " + (lastName || "");
                    const regex = new RegExp(`(${searchStr})`, 'gi');
                    const parts = fullname.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                }
                if (cell.column.type === "address") {
                    if (!['.city', '.country', '.postalCode', '.state'].includes(cell.column.id)) {
                        const { streetAddressLine1, streetAddressLine2 } = cell.value || {};
                        let address = streetAddressLine1 || '';
                        if (streetAddressLine2) {
                            address += `, ${streetAddressLine2}`;
                        }
                        const regex = new RegExp(`(${searchStr})`, 'gi');
                        const parts = address.toString().split(regex);
                        const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                            (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                            : (part));
                        return data
                    }
                }
            } else if (cell.column.type === "boolean" || cell.column.type === "privacypolicy" || cell.column.type === "termsconditions") {
                searchStr = searchStr ? "Yes" : "No";
                const regex = new RegExp(`(${searchStr})`, 'gi');
                let value = cell.value ? "Yes" : "No"
                const parts = value.toString().split(regex);
                const data = parts.map((part: any, index: any) => part === searchStr ?
                    (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                    : (part));
                return data
            } else if(cell.column.type === "dropdown" && cell.value){       
                    searchStr = searchStr.Value
                    const regex = new RegExp(`(${searchStr})`, 'gi');
                    const parts = cell.value.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part === searchStr ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                
            } else {
                if (!searchStr) return cell.value;
                const regex = new RegExp(`(${searchStr})`, 'gi');
                if (cell.value) {
                    const parts = cell.value.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                }
            }
        } else {
            return cell.render("Cell");
        }
    };
    return (
        <>
            <div ref={gridContainerRef} className="table-responsive grid-table-container border_table">
                <table {...getTableProps()} className="table table-bordered">
                    <thead>
                        {headerGroups.map((headerGroup: any, headerGroupIndex: number) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <React.Fragment key={`headerGroup-${headerGroupIndex}`}>
                                    <tr {...headerGroupProps} className="head-row title">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th
                                                    key={`header-${headerGroupIndex}-${columnIndex}`}
                                                    {...columnProps}
                                                    className="header-name">
                                                    {column.render("Header")}
                                                    {!column.disableSortBy && (
                                                        <i
                                                            className={`myIcon fas fa-sort ${column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? "sort-desc"
                                                                    : "sort-asc"
                                                                : ""}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                column.toggleSortBy(!column.isSortedDesc);
                                                            }}
                                                        ></i>
                                                    )}
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                    />
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {gridOptions.searchColumn !== false &&   <tr key={`filterRow-${headerGroupIndex}`} {...headerGroupProps} className="head-row">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th
                                                    key={`filter-${headerGroupIndex}-${columnIndex}`}
                                                    {...columnProps}
                                                    className="search-input">
                                                    {column.canFilter ? renderFilter(column) : null}
                                                </th>
                                            );
                                        })}
                                    </tr> }
                                </React.Fragment>
                            );
                        })}
                    </thead>
                    {loader && (
                        <div className="loader-overlay">
                            <img src={loaderImage} alt="Loading..." />
                            <span className="no-records-message">No record found</span>
                        </div>
                    )}
                    {!loader && <tbody {...getTableBodyProps()}>
                        {page.map((row: any, rowIndex: number) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();
                            return (
                                <tr key={`row-${row.id}`} {...rowProps} >
                                    {row.cells.map((cell: any, cellIndex: number) => {
                                        const { key: cellKey, ...cellProps } = cell.getCellProps();
                                        const cellValue = cell.render("Cell");
                                        return (
                                            <td key={`cell-${row.id}-${cellIndex}`} {...cellProps} className="input-with-ellipsis">
                                                {/* {highlightContent ? <span>{cell.render("Cell")}</span> : cell.render("Cell")} */}
                                                {/* {highlightContent ? highlightText(cell) : cell.render("Cell")} */}
                                                {highlight ? highlightText(cell) : cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>}
                </table>
            </div>

            <div className="pagination-container">
                <div className="per-page">
                    <span>
                        Per Page{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                onPageSizeChange(Number(e.target.value));
                            }}
                        >
                            {[10, 15, 20, 25, 50, 75, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {totalRecord > pageSize && (
                    <div className="pagination-controls">
                        <button
                            onClick={() => {
                                previousPage();
                                onPageChange(tablePageIndex - 1);
                            }}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        {renderPageNumbers()}
                        <button
                            onClick={() => {
                                nextPage();
                                onPageChange(tablePageIndex + 1);
                            }}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default DataGrid;
