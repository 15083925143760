import { ComponentCollection, Serializer, SvgRegistry, type Question } from "survey-core";

SvgRegistry.registerIconFromSvg("icon-month", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"/></svg>`);

 
(<any>ComponentCollection).Instance.add({
    name: "multilookupfield",
    title: "Multi Look UP",
    iconName: 'icon-search',
    questionJSON: {
        name: "multilookupfield",
        type: "dropdown",
        choicesLazyLoadEnabled: true,
        choicesLazyLoadPageSize: 20,
        showOtherItem:false,
        placeholder: 'Search...'
    },
    onInit() {
        Serializer.addProperty('multilookupfield', {
            name: "placeholder",
            displayName: 'Placeholder',
            category: 'general',
            type: "string",
            default: 'Search...'
        });
    },
    onLoaded(question: Question) {
        if (question) {
            this.updateProperty(question);
        }
    },
    updateProperty(question: Question) {
        if (!!question.contentQuestion)
            question.contentQuestion.placeholder = question.placeholder
    },
    onItemValuePropertyChanged(question: Question, options: any) {
        if (options.propertyName === "placeholder") {
            this.updateProperty(question);
        }
    },
    onPropertyChanged(question: Question, propertyName: string, newValue: any) {
        if (propertyName === "placeholder") {
            this.updateProperty(question);
        }
    }
});
