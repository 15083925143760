import { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import useAxios from "../../../hooks/useAxios";

const ViewRecord = ({ recordDetail }: any) => {
  const axios = useAxios();
  const [detailRecords, setDetailRecords] = useState([...recordDetail]);

  const formatDate = (dateString: any, isShowTime: any, field?: any) => {
    if (isShowTime) return moment(dateString).format("DD-MM-YYYY h:mm A");
    else
      return field.dateFormat
        ? moment(dateString).format(field.dateFormat)
        : moment(dateString).format("MM-DD-YYYY");
  };

  const downloadFile = async (file: any) => {
    let { data }: any = await axios.get("common/signed-download-url", {
      params: { path: file.Path },
    });
    let link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("hidden", "true");
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    recordDetail.forEach((d: any) => {
      const { field } = d;
      if (d.type == "address") cleanAddressFields(d, field);
    });
  }, []);

  const cleanAddressFields = (d: any, field: any) => {
    if (field.includes(".country")) {
      if (typeof d[field] === "object") {
        d[field] = "";
      } else d[field] = d[field];
    } else if (
      field.includes(".city") ||
      field.includes(".state") ||
      field.includes(".postalCode")
    ) {
      if (typeof d[field] === "object") {
        d[field] = "";
      } else d[field] = d[field];
    }
  };

  useEffect(()=>{
    setDetailRecords(recordDetail);
  },[recordDetail])

  return (
    <>
      <div>
        {detailRecords.map((d: any, i: any) => (
          <div className="row" key={`${i}_details`}>
            {d.field !== "CreatedAt" && (
              <div className="col">
                {d.inputType === "datetime-local" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>
                      {d[d.field] ? moment.utc(d[d.field]).format("DD-MM-YYYY h:mm A") : "" }
                    </span>
                  </div>
                ) : d.inputType === "date" || d.type === "datepicker" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>
                      {d[d.field] ? formatDate(d[d.field], false, d) : ""}
                    </span>
                  </div>
                ) : d.type === "boolean" ||
                  d.type === "privacypolicy" ||
                  d.type === "termsconditions" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>{d[d.field] ? "Yes" : "No"}</span>
                  </div>
                ) : d.type === "tagbox" || d.type === "checkbox" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>{d[d.field] ? d[d.field].join(", ") : ""}</span>
                  </div>
                ) : d.type === "file" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    {d[d.field] && (
                      <div>
                        {d[d.field].map((file: any, index: any) => (
                          <div
                            key={index}
                            className="ml-auto multi-action-button"
                          >
                            <span style={{ fontSize: "15px", color: "blue" }}>
                              {file.FileName}
                            </span>
                            <code style={{ fontSize: "15px", color:"#bb2124", marginLeft:"5px"}}>
                              {(parseInt(file.Size) / 1024).toFixed(2)} MB
                            </code>
                            <i
                              onClick={() => downloadFile(file)}
                              className="btn btn-sm far fa-download download-file ml-10"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Download"
                              style={{ marginLeft:"10px"}}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : d.type === "fullname" ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>
                      {d[d.field]?.prefixDropdown &&
                      d.prefixVisible &&
                      d.prefixFieldType !== "text"
                        ? `${d[d.field]?.prefixDropdown} `
                        : ""}
                      {d[d.field]?.prefixText &&
                      d.prefixVisible &&
                      d.prefixFieldType === "text"
                        ? `${d[d.field]?.prefixText} `
                        : ""}
                      {d[d.field]?.firstName ? `${d[d.field]?.firstName} ` : ""}
                      {d[d.field]?.middleName && d.middleNameVisible
                        ? `${d[d.field]?.middleName} `
                        : ""}
                      {d[d.field]?.lastName || ""}
                    </span>
                  </div>
                ) : d.type === "address" &&
                  !d.field.includes(".city") &&
                  !d.field.includes(".country") &&
                  !d.field.includes(".state") &&
                  !d.field.includes(".postalCode") ? (
                  <div className="bg_blue p-3">
                    <h4 className="font-weight-bold">{d.label}</h4>
                    <span>
                      {d[d.field]?.streetAddressLine1 || ""},{" "}
                      {d[d.field]?.streetAddressLine2 || ""}
                    </span>
                  </div>
                ) : (
                  d.field !== "_id" && (
                    <div className="bg_blue p-3">
                      <h4 className="font-weight-bold">{d.label}</h4>
                      <span>{d[d.field]}</span>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <style>
        {`
            .font-weight-bold {
                font-weight: 700 !important;
            }
            
            `}
      </style>
    </>
  );
};

export default ViewRecord;
