import { ComponentCollection, Serializer, SvgRegistry } from "survey-core";
import { PropertyGridEditorCollection } from "survey-creator-react";
import store from "../../store";

SvgRegistry.registerIconFromSvg("icon-address", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M408 120c0 8.6-1.8 18.3-5 28.6c-5.7 18.4-15.6 38.8-27.4 59.2l-.1 .2c-3.1 5.4-6.3 10.7-9.6 16c-4.4 7-8.9 14-13.5 20.8l-.4 .6c-18.1 26.9-36.7 51.1-49.2 66.6c-7.7 9.6-22 9.6-29.6 0c-12.4-15.5-31.1-39.7-49.2-66.6c-18.4-27.3-36.3-57.5-46.7-84.4c-.8-2.1-1.6-4.2-2.3-6.3c-4.4-12.6-7-24.4-7-34.6c0-4.7 .3-9.3 .8-13.8C175.6 46.4 226.4 0 288 0c66.3 0 120 53.7 120 120zM352 300.6c13.5-18.2 28.8-39.9 42.7-62.4c1.8-2.9 3.6-5.8 5.3-8.8V453.7l128-46.5V154.3L419.3 193.8c2.2-4.7 4.4-9.3 6.3-14c5.9-14 11-29.2 13.2-44.2l105-38.2c7.4-2.7 15.6-1.6 22 2.9s10.2 11.8 10.2 19.7V424c0 10.1-6.3 19.1-15.8 22.6l-176 64c-5.3 1.9-11.1 1.9-16.4 0L200 449.5l-167.8 61c-7.4 2.7-15.6 1.6-22-2.9S0 495.8 0 488V184c0-10.1 6.3-19.1 15.8-22.6L136 117.7c0 .8 0 1.5 0 2.3c0 15.1 3.7 30.7 8.9 45.6L48 200.8V453.7l128-46.5V229.5c1.8 3 3.5 5.9 5.3 8.8c13.9 22.5 29.1 44.2 42.7 62.4V407.2l128 46.5V300.6zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>`);

const fieldConfig = [
    {
        name: "streetAddressLine1",
        title: "Street Address 1",
        isRequired: false,
        maxLength: 150,
        placeholder: "Enter street address 1"
    },
    {
        name: "streetAddressLine2",
        title: "Street Address 2",
        isRequired: false,
        maxLength: 150,
        placeholder: "Enter street address 2",
        // startWithNewLine: false
    },
    {
        name: "cityDropdown",
        title: "City",
        isRequired: true,
        type: 'dropdown',
        choicesLazyLoadEnabled: true,
        choicesLazyLoadPageSize: 20,
        showOtherItem:true,
        visibleIfEnable: false
    },
    {
        name: "city",
        title: "City",
        maxLength: 100,
        isRequired: false,
        placeholder: "Enter city",
        visibleIfEnable: false,
    },
    {
        name: "state",
        title: "State / Province",
        isRequired: false,
        maxLength: 100,
        placeholder: "Enter state or province",
        isReadOnly: true
        // startWithNewLine: false
    },
    {
        name: "country",
        title: "Country",
        type: "dropdown",
        isRequired: false,
        placeholder: "Enter country",
        choicesByUrl: { "url": "https://surveyjs.io/api/CountriesExample" },
        isReadOnly: true
    },
    {
        name: "postalCode",
        title: "Postal / Zipcode",
        isRequired: false,
        maxLength: 10,
        placeholder: "Enter postal or zipcode",
        // startWithNewLine: false
    }
];

const cityProperties = ['cityPlaceholder', 'cityLabel', 'cityIsRequired', 'showCity'];
const cityDropdownProperties = ['cityDropdownPlaceholder', 'cityDropdownLabel', 'cityDropdownIsRequired', 'showCityDropdown'];

let projectConfig: any;

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function updateFieldLayout(question: any) {
    let visibleFieldCount = 0;

    fieldConfig.forEach(fc => {
        let field = question.contentPanel.getQuestionByName(fc.name);
        if (field) {
            // Set startWithNewLine based on the number of visible fields before this one
            field.startWithNewLine = (visibleFieldCount % 2 === 0);

            // Increment count if the field is visible
            if (field.visible) {
                visibleFieldCount++;
            }
        }
    });
}

PropertyGridEditorCollection.register({
    fit: function (prop) {
        return prop.type === "countryList";
    },
    getJSON: function () {
        return {
            type: "dropdown",
            choicesByUrl: { "url": "https://surveyjs.io/api/CountriesExample" },
            default: 'United Kingdom'
        };
    },
});


ComponentCollection.Instance.add({
    name: "address",
    title: "Address",
    iconName: 'icon-address',
    elementsJSON: fieldConfig.map((field: any) => {
        if(field.name == "cityDropdown") {
            return {
                type: field.type || "text",
                name: field.name,
                title: field.title,
                isRequired: field.isRequired,
                startWithNewLine: field.startWithNewLine,
                placeholder: field.placeholder,
                maxLength: field.maxLength,
                cssClasses: 'inline-field',
                choicesByUrl: field.choicesByUrl,
                choicesLazyLoadEnabled: field.choicesLazyLoadEnabled,
                choicesLazyLoadPageSize: field.choicesLazyLoadPageSize,
                showOtherItem:field.showOtherItem,
            }
        }
        return { 
        type: field.type || "text",
        name: field.name,
        title: field.title,
        isRequired: field.isRequired,
        maxLength: field.maxLength,
        // startWithNewLine: field.startWithNewLine,
        placeholder: field.placeholder,
        cssClasses: 'inline-field',
        choicesByUrl: field.choicesByUrl
    } 
}),

    onInit() {
        Serializer.addProperty('address', {
            name: `defaultCountry:countryList`,
            displayName: 'Default Country',
            visibleIf: (obj: any) => obj.showCountry,
            category: 'general'
        });

        Serializer.addProperty('address', {
            name: `cityExtraData`,
            type: 'string',
            visible:false,
            category: 'general'
        });

        fieldConfig.forEach(field => {

            Serializer.addProperty('address', {
                name: `${field.name}Label`,
                type: 'string',
                displayName: field.title,
                category: 'Labels',
                default: field.title
            });

            Serializer.addProperty('address', {
                name: `${field.name}Placeholder`,
                displayName: field.title,
                type: 'string',
                category: 'Placeholders',
                default: field.placeholder
            });

            Serializer.addProperty('address', {
                name: `${field.name}IsRequired`,
                type: 'boolean',
                displayName: field.title,
                category: 'Required Fields',
                default: field.isRequired
            });

            Serializer.addProperty('address', {
                name: `show${capitalize(field.name)}`,
                type: 'boolean',
                displayName: field.title,
                category: 'Visibility',
                default: true
            });
        });
    },
    onLoaded(question) {
        projectConfig = store.getState().project;
        question.title = question.title || 'Address';
        fieldConfig.forEach(fc => {
            let quest = question.contentPanel.getQuestionByName(fc.name);
            if (quest) {
                let customPlaceholder = question[`${fc.name}Placeholder`];
                let customIsRequired = question[`${fc.name}IsRequired`];
                quest.placeholder = customPlaceholder;
                quest.isRequired = customIsRequired !== undefined ? customIsRequired : fc.isRequired;

                let isVisible = question[`show${capitalize(fc.name)}`];
                const isIntegrationEnabled = projectConfig?.formData?.Settings?.Integration?.Optimiser?.status;
                const isStateOrCountryField = fc.name === "state" || fc.name === "country";
                
                if (isIntegrationEnabled) {
                    quest.visible = fc.name == "city" ? fc.visibleIfEnable : isVisible;
                    // Set visibility for city properties to false
                    cityProperties.forEach(prop => {
                        Serializer.getProperty('address', prop).visible = false;
                    });

                    cityDropdownProperties.forEach(prop => {
                        Serializer.getProperty('address', prop).visible = true;
                    });
                
                    if (isStateOrCountryField) {
                        quest.readOnly = fc.isReadOnly;
                    }
                } else {
                    quest.visible = fc.name == "cityDropdown" ? fc.visibleIfEnable : isVisible;
                    // Set visiblity for cityDropdown properties to false and city properties to true
                    cityDropdownProperties.forEach(prop => {
                        Serializer.getProperty('address', prop).visible = false;
                    });
                    
                    cityProperties.forEach(prop => {
                        Serializer.getProperty('address', prop).visible = true;
                    });
                }

                let customLabel = question[`${fc.name}Label`];
                if (customLabel !== undefined) {
                    quest.title = customLabel;
                }

                if (fc.name == 'country') {
                    quest.value = question.defaultCountry;
                }

            }
        });
        // updateFieldLayout(question);
    },
    onPropertyChanged(question, propertyName, newValue) {
        fieldConfig.forEach(({ name: fieldName, placeholder, title }) => {
            let field = question.contentPanel.elements.find((q: any) => q.name === fieldName);
            if (field) {
                if (propertyName === `${fieldName}Placeholder`) {
                    field.placeholder = newValue !== undefined ? newValue : placeholder;
                } else if (propertyName === `${fieldName}IsRequired`) {
                    field.isRequired = newValue;
                } else if (propertyName === `show${capitalize(fieldName)}`) {
                    field.visible = newValue;
                    // updateFieldLayout(question);
                } else if (propertyName === `${fieldName}Label`) {
                    field.title = newValue || title;
                }
            }
        });

        if (propertyName == 'defaultCountry') {
            let field = question.contentPanel.elements.find((q: any) => q.name === 'country');
            if (field) {
                field.defaultValue = newValue
            }
        }
    }
});