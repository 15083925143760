import { CustomWidgetCollection, Serializer, Question, SvgRegistry } from "survey-core";

const WIDGET_NAME = 'customlabel';

SvgRegistry.registerIconFromSvg('icon-header', `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 96c-17.7 0-32 14.3-32 32V384c0 17.7 14.3 32 32 32H576c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H64zM0 128C0 92.7 28.7 64 64 64H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm96 32v64H320V160H96zm-32 0c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160zm0 208c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm320 0c0-8.8 7.2-16 16-16H560c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zM80 288a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm48 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm80-16a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm48 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm80-16a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"/></svg>`)

CustomWidgetCollection.Instance.add({
    name: WIDGET_NAME,
    title: "Label / Sub-label",
    iconName: 'icon-header',
    htmlTemplate: `
        <div class="custom-widget-label-sublabel">
            <h3 class="label-title" id="custom-label"></h3>
            <p class="label-description" id="custom-sublabel"></p>
        </div>
    `,
    widgetIsLoaded: () => true,
    isFit: (question: Question) => {
        if (question.getType() === WIDGET_NAME) {
            question.titleLocation = 'hidden';
            question.hideNumber = true;
            question.isRequired = false;
            return true;
        }
        return false;
    },
    init: () => {
        Serializer.addClass(WIDGET_NAME, [
            {
                name: "labelText",
                category: 'general',
                displayName: 'Label Text',
                default: "Label"
            },
            {
                name: "subLabelText",
                displayName: 'Sub Label Text',
                category: 'general',
                default: "Sub Label"
            }

        ], undefined, "empty");

    },
    afterRender: (question: Question, el: any) => {
        const updateText = () => {
            let labelElement = el.querySelector("#custom-label");
            let subLabelElement = el.querySelector("#custom-sublabel");

            labelElement.textContent = question["labelText"];
            subLabelElement.textContent = question["subLabelText"];
        };

        updateText();

        // Subscribe to property changes
        question.registerFunctionOnPropertyValueChanged("labelText", updateText);
        question.registerFunctionOnPropertyValueChanged("subLabelText", updateText);
    },
    willUnmount: (question: Question, el: any) => {
        // Unsubscribe to avoid memory leaks
        question.unRegisterFunctionOnPropertyValueChanged("labelText");
        question.unRegisterFunctionOnPropertyValueChanged("subLabelText");
    }
}, "customtype");
